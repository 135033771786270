import { Box, Typography } from "@mui/material";
import { useCallback } from "react";

import { ActionProducts, addAction, AppShieldingFeatures } from "../../../../../_common/datadog/add-action";
import { useTranslate } from "../../../../../_common/i18n/hooks/use-translate.hook";
import { AsyncConfirmationDialog } from "../../../../../_common/ui/confirmation-dialog/async-confirmation-dialog";
import { GetProject } from "../../projects/api/get-project";
import { useDeleteAccessApiKey } from "../api/delete-access-api-key/use-delete-access-api-key";

interface RevokeAccessApiKeyDialogProps {
  title: string;
  content: string;
  project?: GetProject;
  onRevoked?: () => void;
  onCancel: () => void;
  open: boolean;
}

export function RevokeAccessApiKeyDialog({
  project,
  onCancel,
  open,
  title,
  content,
  onRevoked,
}: RevokeAccessApiKeyDialogProps) {
  const t = useTranslate();
  const { mutateAsync: deleteAccessApiKeyAsync } = useDeleteAccessApiKey(project);

  const handleRevokeAccessApiKey = useCallback(() => {
    addAction({
      name: "revoke-configuration-key",
      product: ActionProducts.AppShielding,
      feature: AppShieldingFeatures.ProjectsDetails,
    });
    return deleteAccessApiKeyAsync({ projectId: project!.id, apiKeyId: project!.apiKeyId! }).then(onCancel);
  }, [deleteAccessApiKeyAsync, onCancel, project]);

  return (
    <AsyncConfirmationDialog
      onConfirm={handleRevokeAccessApiKey}
      onConfirmSuccess={onRevoked}
      onCancel={onCancel}
      open={open}
      title={title}
      reverseButtonOrder
      confirmButtonLabel={t("component.api-access.revoke-dialog-confirm.revoke-button")}
      type="error"
    >
      <Box width={440}>
        <Box marginBottom="20px">
          <Typography color="textPrimary">{content}</Typography>
        </Box>
      </Box>
    </AsyncConfirmationDialog>
  );
}
